import { pluralize } from '../utils/translation';

export default {
  checkIn: 'Check-in Date',
  checkOut: 'Check-out Date',
  guests: 'Guests',
  rooms: 'Rooms',
  adults: 'Adults',
  children: 'Children',
  checkAvailability: 'Check availability',
  roomCount: ({ count }) => pluralize(
    count,
    '{count} rooms',
    ['{count} room', 1]
  ),
  adultCount: ({ count }) => pluralize(
    count,
    '{count} adults',
    ['{count} adult', 1]
  ),
  childrenCount: ({ count }) => pluralize(
    count,
    '{count} children',
    ['{count} child', 1]
  )
};
