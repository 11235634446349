// Cast prop to string, number or boolean
export const castProp = (prop) => {
  if (!isNaN(prop)) {
    return +prop;
  }

  if (prop === 'true') {
    return true;
  }

  if (prop === 'false') {
    return false;
  }

  return decodeURIComponent(prop);
};

export const collectPropsFromUrl = (queryString) => {
  const queryParams = {};

  if (!queryString) {
    return queryParams;
  }

  queryString
    .substr(1)
    .split('&')
    .forEach((item) => {
      const [key, value] = item.split('=');

      queryParams[key] = castProp(value);
    });

  return queryParams;
};
