import 'moment/locale/sl';

import { pluralize } from '../utils/translation';

export default {
  checkIn: 'Datum prijave',
  checkOut: 'Datum odjave',
  guests: 'Gostje',
  rooms: 'Sobe',
  adults: 'Odrasli',
  children: 'Otroci',
  checkAvailability: 'Preveri razpoložljivost',
  roomCount: ({ count }) => pluralize(
    count,
    '{count} sob',
    ['{count} soba', 1],
    ['{count} sobi', 2],
    ['{count} sobe', 3, 4]
  ),
  adultCount: ({ count }) => pluralize(
    count,
    '{count} odraslih',
    ['{count} odrasel', 1],
    ['{count} odrasla', 2],
    ['{count} odrasli', 3, 4]
  ),
  childrenCount: ({ count }) => pluralize(
    count,
    '{count} otrok',
    ['{count} otroka', 2],
    ['{count} otroci', 3, 4]
  )
};
