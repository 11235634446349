import { createMuiTheme } from '@material-ui/core/styles';
import * as colors from '@material-ui/core/colors';

export const createTheme = props => createMuiTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    type: props.theme,
    primary: availableColors[props.color],
    background: {
      default: 'transparent'
    }
  },
  overrides: {
    MuiPopover: {
      paper: {
        top: `${props.position === 'bottom' ? 'auto' : `${props.height}px`} !important`,
        bottom: props.position === 'bottom' && `${props.height}px`
      }
    }
  }
});

export const availableColors = {
  red: colors.red,
  pink: colors.pink,
  purple: colors.purple,
  deepPurple: colors.deepPurple,
  indigo: colors.indigo,
  blue: colors.blue,
  lightBlue: colors.lightBlue,
  cyan: colors.cyan,
  teal: colors.teal,
  green: colors.green,
  lightGreen: colors.lightGreen,
  lime: colors.lime,
  yellow: colors.yellow,
  amber: colors.amber,
  orange: colors.orange,
  deepOrange: colors.deepOrange,
  brown: colors.brown
};
