import 'moment/locale/es';

import { pluralize } from '../utils/translation';

export default {
  checkIn: 'Fecha de entrada',
  checkOut: 'Fecha de salida',
  guests: 'Huéspedes',
  rooms: 'Habitaciones',
  adults: 'Adultos',
  children: 'Menores',
  checkAvailability: 'Ver disponibilidad',
  roomCount: ({ count }) => pluralize(
    count,
    '{count} habitaciones',
    ['{count} habitación', 1]
  ),
  adultCount: ({ count }) => pluralize(
    count,
    '{count} adultos',
    ['{count} adulto', 1]
  ),
  childrenCount: ({ count }) => pluralize(
    count,
    '{count} niños',
    ['{count} niño', 1]
  )
};
