import 'moment/locale/ru';

import { pluralize } from '../utils/translation';

export default {
  checkIn: 'Дата заезда',
  checkOut: 'Дата отъезда',
  guests: 'Гостей',
  rooms: 'Номера',
  adults: 'Взрослых',
  children: 'Детей',
  checkAvailability: 'Проверить наличие мест',
  roomCount: ({ count }) => pluralize(
    count,
    '{count} номеров',
    ['{count} номер', 1, 21],
    ['{count} номера', [2, 4], [22, 24]]
  ),
  adultCount: ({ count }) => pluralize(
    count,
    '{count} взрослых',
    ['{count} взрослый', 1, 21]
  ),
  childrenCount: ({ count }) => pluralize(
    count,
    '{count} детей',
    ['{count} ребенок', 1]
  )
};
