import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  root: {
    height: '100%',
    boxShadow: 'none',
    '&:focus': {
      boxShadow: 'none'
    }
  }
});

const AvailabilityButton = ({ classes, children, disabled, onClick }) => (
  <Button
    className={classes.root}
    fullWidth
    variant="contained"
    size="large"
    color="primary"
    disabled={disabled}
    onClick={onClick}>
    {children}
  </Button>
);

AvailabilityButton.defaultProps = {
  disabled: false
};

AvailabilityButton.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func
};

export default withStyles(styles)(AvailabilityButton);
