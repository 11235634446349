import 'moment/locale/hu';

export default {
  checkIn: 'Érkezés napja',
  checkOut: 'Távozás napja',
  guests: 'Vendégek',
  rooms: 'Szobák',
  adults: 'Felnőttek',
  children: 'Gyermekek',
  checkAvailability: 'Szabad helyek',
  roomCount: '{count} szoba',
  adultCount: '{count} felnőtt',
  childrenCount: '{count} gyerek'
};
