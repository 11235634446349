import 'moment/locale/it';

import { pluralize } from '../utils/translation';

export default {
  checkIn: 'Check-in',
  checkOut: 'Check-out',
  guests: 'Ospiti',
  rooms: 'Camere',
  adults: 'Adulti',
  children: 'Bambini',
  checkAvailability: 'Verifica disponibilità',
  roomCount: ({ count }) => pluralize(
    count,
    '{count} camere',
    ['{count} camera', 1]
  ),
  adultCount: ({ count }) => pluralize(
    count,
    '{count} adulti',
    ['{count} adulto', 1]
  ),
  childrenCount: ({ count }) => pluralize(
    count,
    '{count} bambini',
    ['{count} bambino', 1]
  )
};
