import 'moment/locale/hr';

import { pluralize } from '../utils/translation';

export default {
  checkIn: 'Datum prijave',
  checkOut: 'Datum odjave',
  guests: 'Gosti',
  rooms: 'Sobe',
  adults: 'Odrasli',
  children: 'Djeca',
  checkAvailability: 'Provjeri raspoloživost',
  roomCount: ({ count }) => pluralize(
    count,
    '{count} jedinica',
    ['{count} jedinice', [2, 4], [22, 24]]
  ),
  adultCount: ({ count }) => pluralize(
    count,
    '{count} odraslih',
    ['{count} odrasla osoba', 1, 21]
  ),
  childrenCount: ({ count }) => pluralize(
    count,
    '{count} djece',
    ['{count} dijete', 1]
  )
};
