import React from 'react';
import PropTypes from 'prop-types';
import InputAdornment from '@material-ui/core/InputAdornment';
import DateRangeIcon from '@material-ui/icons/DateRange';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { InlineDatePicker } from 'material-ui-pickers';

import NavigateIcon from './NavigateIcon';

const DatePicker = React.forwardRef(({
  value,
  label,
  minDate,
  maxDate,
  prevDisabled,
  nextDisabled,
  disabled,
  onOpen,
  onChange,
  onBackClick,
  onNextClick
}, ref) => (
    <InlineDatePicker
      variant="outlined"
      fullWidth
      onlyCalendar
      minDate={minDate}
      maxDate={maxDate}
      label={label}
      value={value}
      format={'LL'}
      disabled={disabled}
      onOpen={onOpen}
      onChange={onChange}
      PopoverProps={{
        transitionDuration: { enter: 200, exit: 0 }
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <DateRangeIcon color={disabled ? 'disabled' : 'primary'} />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end" onClick={event => event.stopPropagation()}>
            <NavigateIcon
              Icon={NavigateBeforeIcon}
              disabled={prevDisabled}
              onClick={onBackClick}
            />
            <NavigateIcon
              Icon={NavigateNextIcon}
              disabled={nextDisabled}
              onClick={onNextClick}
            />
          </InputAdornment>
        )
      }}
      ref={ref}
    />
  ));

DatePicker.defaultProps = {
  disabled: false
};

DatePicker.propTypes = {
  value: PropTypes.object.isRequired,
  label: PropTypes.string,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  prevDisabled: PropTypes.bool,
  nextDisabled: PropTypes.bool,
  disabled: PropTypes.bool,
  onOpen: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onBackClick: PropTypes.func,
  onNextClick: PropTypes.func
};

export default DatePicker;
