import 'moment/locale/pl';

import { pluralize } from '../utils/translation';

export default {
  checkIn: 'Od',
  checkOut: 'Do',
  guests: 'Goście',
  rooms: 'Pokoje',
  adults: 'Dorośli',
  children: 'Dzieci',
  checkAvailability: 'Sprawdź dostępność',
  roomCount: ({ count }) => pluralize(
    count,
    '{count} pokój',
    ['{count} pokoje', [2, 4], [22, 24]]
  ),
  adultCount: ({ count }) => pluralize(
    count,
    '{count} dorosłych',
    ['{count} dorosły', 1]
  ),
  childrenCount: ({ count }) => pluralize(
    count,
    '{count} dzieci',
    ['{count} dziecko', 1]
  )
};
