import 'moment/locale/sv';

import { pluralize } from '../utils/translation';

export default {
  checkIn: 'Incheckning',
  checkOut: 'Utcheckning',
  guests: 'Gäster',
  rooms: 'Rum',
  adults: 'Vuxna',
  children: 'Barn',
  checkAvailability: 'Se lediga rum',
  roomCount: '{count} rum',
  adultCount: ({ count }) => pluralize(
    count,
    '{count} vuxna',
    ['{count} vuxen', 1]
  ),
  childrenCount: '{count} barn'
};
