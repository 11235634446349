import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import PeopleIcon from '@material-ui/icons/People';
import TextField from '@material-ui/core/TextField';
import Popover from '@material-ui/core/Popover';

import Counter from './Counter';

const styles = (theme) => ({
  compactInput: {
    fontSize: '80%'
  },
  counter: {
    margin: theme.spacing.unit * 2
  }
});

class GuestOptions extends Component {
  constructor() {
    super();

    this.state = {
      anchorEl: null
    };
  }

  onClick = (event) => {
    event.stopPropagation();
    event.preventDefault();

    const { disabled } = this.props;

    if (disabled) {
      return;
    }

    this.setState({ anchorEl: event.currentTarget });
  };

  onChange = (key, value) => {
    const { roomCount, adultCount, childrenCount, onChange } = this.props;
    const options = { roomCount, adultCount, childrenCount };
    options[key] = value;

    onChange(options);
  };

  onOpen = () => {
    const { onOpen } = this.props;

    onOpen();
  };

  onClose = () => {
    const { onClose } = this.props;

    this.setState({ anchorEl: null });

    onClose();
  };

  render() {
    const {
      classes,
      translate,
      label,
      disabled,
      roomCount,
      adultCount,
      childrenCount,
      hideChildrenCounter
    } = this.props;
    const { anchorEl } = this.state;
    const open = !!anchorEl;

    const value = [
      translate({ text: 'roomCount', data: { count: roomCount } }),
      translate({ text: 'adultCount', data: { count: adultCount } }),
      childrenCount && translate({ text: 'childrenCount', data: { count: childrenCount } })
    ]
      .filter((count) => !!count)
      .join(', ');

    return (
      <React.Fragment>
        <TextField
          aria-owns={open && 'guest-options-popper'}
          aria-haspopup="true"
          variant="outlined"
          fullWidth
          label={label}
          disabled={disabled}
          value={value}
          InputProps={{
            readOnly: true,
            classes: {
              input: childrenCount && classes.compactInput
            },
            startAdornment: (
              <InputAdornment position="start">
                <PeopleIcon color={disabled ? 'disabled' : 'primary'} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <ArrowDropDownIcon />
              </InputAdornment>
            )
          }}
          onClick={this.onClick}
        />
        <Popover
          id="guest-options-popper"
          className={classes.popover}
          open={open}
          anchorEl={anchorEl}
          onEnter={this.onOpen}
          onClose={this.onClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transitionDuration={{ enter: 200, exit: 0 }}>
          <Counter
            classes={{ root: classes.counter }}
            label={translate({ text: 'rooms' })}
            value={roomCount}
            min={1}
            max={30}
            onChange={(value) => this.onChange('roomCount', value)}
          />
          <Counter
            classes={{ root: classes.counter }}
            label={translate({ text: 'adults' })}
            value={adultCount}
            min={1}
            max={30}
            onChange={(value) => this.onChange('adultCount', value)}
          />
          {!hideChildrenCounter && (
            <Counter
              classes={{ root: classes.counter }}
              label={translate({ text: 'children' })}
              value={childrenCount}
              min={0}
              max={10}
              onChange={(value) => this.onChange('childrenCount', value)}
            />
          )}
        </Popover>
      </React.Fragment>
    );
  }
}

GuestOptions.defaultProps = {
  disabled: false,
  hideChildrenCounter: false,
  roomCount: 0,
  adultCount: 0,
  childrenCount: 0
};

GuestOptions.propTypes = {
  classes: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  hideChildrenCounter: PropTypes.bool,
  roomCount: PropTypes.number,
  adultCount: PropTypes.number,
  childrenCount: PropTypes.number,
  onChange: PropTypes.func,
  onOpen: PropTypes.func,
  onClose: PropTypes.func
};

export default withStyles(styles)(GuestOptions);
