import 'moment/locale/ms';

export default {
  checkIn: 'Daftar masuk',
  checkOut: 'Daftar keluar',
  guests: 'Tetamu',
  rooms: 'Bilik',
  adults: 'Dewasa',
  children: 'Kanak-kanak',
  checkAvailability: 'Semak ketersediaan',
  roomCount: '{count} bilik',
  adultCount: '{count} dewasa',
  childrenCount: '{count} kanak-kanak'
};
