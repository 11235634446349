import 'moment/locale/el';

import { pluralize } from '../utils/translation';

export default {
  checkIn: 'Ημερομηνία check-in',
  checkOut: 'Ημερομηνία check-out',
  guests: 'Πελάτες',
  rooms: 'Δωμάτια',
  adults: 'Ενήλικες',
  children: 'Παιδιά',
  checkAvailability: 'Ελέγξτε τη διαθεσιμότητα',
  roomCount: ({ count }) => pluralize(
    count,
    '{count} δωμάτια',
    ['{count} δωμάτιο', 1]
  ),
  adultCount: ({ count }) => pluralize(
    count,
    '{count} ενήλικες',
    ['{count} ενήλικας', 1]
  ),
  childrenCount: ({ count }) => pluralize(
    count,
    '{count} παιδιά',
    ['{count} παιδί', 1]
  )
};
