import 'moment/locale/zh-cn';

export default {
  checkIn: '入住日期',
  checkOut: '退房日期',
  guests: '顾客',
  rooms: '客房',
  adults: '成人',
  children: '儿童',
  checkAvailability: '查看空房情况',
  roomCount: '{count}间房',
  adultCount: '{count}位成人',
  childrenCount: '{count}名儿童'
};
