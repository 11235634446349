import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    width: '100%'
  },
});

const Box = ({ classes, children, position }) => (
  <Paper className={classes.root} elevation={0} square={true} style={{
    position: 'fixed',
    top: position === 'top' && 0,
    bottom: position === 'bottom' && 0
  }}>
    {children}
  </Paper>
);

Box.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  position: PropTypes.oneOf(['top', 'bottom'])
};

export default withStyles(styles)(Box);
