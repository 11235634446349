import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { Typography } from '@material-ui/core';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  label: {
    marginRight: theme.spacing.unit * 4
  },
  selector: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 120
  },
  button: {
    minWidth: 0,
    padding: 5
  }
});

const Counter = ({ classes, label, value, min, max, onIncrease, onDecrease, onChange }) => (
  <div className={classes.root}>
    <Typography className={classes.label}>
      {label}
    </Typography>
    <div className={classes.selector}>
      <Button
        className={classes.button}
        variant="outlined"
        color="primary"
        disabled={value <= min}
        onClick={() => {
          if (onDecrease) {
            onDecrease();
          }

          onChange(value - 1);
        }}>
        <RemoveIcon />
      </Button>
      <Typography>{value}</Typography>
      <Button
        className={classes.button}
        variant="outlined"
        color="primary"
        disabled={value >= max}
        onClick={() => {
          if (onIncrease) {
            onIncrease();
          }

          onChange(value + 1);
        }}>
        <AddIcon />
      </Button>
    </div>
  </div>
);

Counter.defaultProps = {
  value: 0
};

Counter.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  onIncrease: PropTypes.func,
  onDecrease: PropTypes.func,
  onChange: PropTypes.func.isRequired
};

export default withStyles(styles)(Counter);
