import 'moment/locale/is';

import { pluralize } from '../utils/translation';

export default {
  checkIn: 'Innritunardagur',
  checkOut: 'Útritunardagur',
  guests: 'Gestir',
  rooms: 'Herbergi',
  adults: 'Fullorðnir',
  children: 'Börn',
  checkAvailability: 'Athuga framboð',
  roomCount: '{count} herbergi',
  adultCount: '{count} fullorðnir',
  childrenCount: ({ count }) => pluralize(
    count,
    '{count} börn',
    ['{count} barn', 1]
  )
};
