import 'moment/locale/da';

import { pluralize } from '../utils/translation';

export default {
  checkIn: 'Ankomstdato',
  checkOut: 'Afrejsedato',
  guests: 'Gæster',
  rooms: 'Værelser',
  adults: 'Voksne',
  children: 'Børn',
  checkAvailability: 'Søg efter ledige værelser',
  roomCount: ({ count }) => pluralize(
    count,
    '{count} værelser',
    ['{count} værelse', 1]
  ),
  adultCount: ({ count }) => pluralize(
    count,
    '{count} voksne',
    ['{count} voksen', 1]
  ),
  childrenCount: ({ count }) => pluralize(
    count,
    '{count} børn',
    ['{count} barn', 1]
  )
};
