import React from 'react';
import PropTypes from 'prop-types';

const NavigateIcon = ({ Icon, disabled, onClick }) => (
  <Icon
    onClick={() => !disabled && onClick()}
    color={disabled ? 'disabled' : 'inherit'}
    style={{ cursor: disabled ? 'initial' : 'pointer' }}
  />
);

NavigateIcon.propTypes = {
  Icon: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func
};

export default NavigateIcon;
