import 'moment/locale/nb';

import { pluralize } from '../utils/translation';

export default {
  checkIn: 'Innsjekkingsdato',
  checkOut: 'Utsjekkingsdato',
  guests: 'Gjester',
  rooms: 'Rom',
  adults: 'Voksne',
  children: 'Barn',
  checkAvailability: 'Søk etter ledige rom',
  roomCount: '{count} rom',
  adultCount: ({ count }) => pluralize(
    count,
    '{count} voksne',
    ['{count} voksen', 1]
  ),
  childrenCount: '{count} barn'
};
