import 'moment/locale/fr';

import { pluralize } from '../utils/translation';

export default {
  checkIn: 'Du',
  checkOut: 'Au',
  guests: 'Occupants',
  rooms: 'Chambres',
  adults: 'Adultes',
  children: 'Enfants',
  checkAvailability: 'Vérifier la disponibilité',
  roomCount: ({ count }) => pluralize(
    count,
    '{count} chambres',
    ['{count} chambre', 1]
  ),
  adultCount: ({ count }) => pluralize(
    count,
    '{count} adultes',
    ['{count} adulte', 1]
  ),
  childrenCount: ({ count }) => pluralize(
    count,
    '{count} enfants',
    ['{count} enfant', 1]
  )
};
