import 'moment/locale/th';

export default {
  checkIn: 'วันที่เช็คอิน',
  checkOut: 'วันที่เช็คเอาท์',
  guests: 'จำนวนแขก',
  rooms: 'ห้องพัก',
  adults: 'ผู้ใหญ่',
  children: 'เด็ก',
  checkAvailability: 'เช็คห้องว่าง',
  roomCount: '{count} ห้องพัก',
  adultCount: 'ผู้ใหญ่ {count}',
  childrenCount: 'เด็ก {count}'
};
