import moment from 'moment';

function bookingFormatter(url, props) {
  const formattedUrl = new URL(url);
  formattedUrl.searchParams.set('checkin_monthday', props.startDate.monthday);
  formattedUrl.searchParams.set('checkin_month', props.startDate.month);
  formattedUrl.searchParams.set('checkin_year', props.startDate.year);
  formattedUrl.searchParams.set('checkout_monthday', props.endDate.monthday);
  formattedUrl.searchParams.set('checkout_month', props.endDate.month);
  formattedUrl.searchParams.set('checkout_year', props.endDate.year);
  formattedUrl.searchParams.set('no_rooms', props.roomCount);
  formattedUrl.searchParams.set('group_adults', props.adultCount);
  formattedUrl.searchParams.set('group_children', props.childrenCount);
  formattedUrl.searchParams.set(
    'room1',
    [...Array(props.adultCount).fill('A'), ...Array(props.childrenCount).fill(12)].join(',')
  );

  return formattedUrl.href;
}

function tripadvisorFormatter(url, props) {
  const formattedUrl = new URL(url);
  formattedUrl.searchParams.set('adults', props.adultCount);
  formattedUrl.searchParams.set('inDay', props.startDate.monthday);
  formattedUrl.searchParams.set('inMonth', props.startDate.month);
  formattedUrl.searchParams.set('inYear', props.startDate.year);
  formattedUrl.searchParams.set('outDay', props.endDate.monthday);
  formattedUrl.searchParams.set('outMonth', props.endDate.month);
  formattedUrl.searchParams.set('outYear', props.endDate.year);
  formattedUrl.searchParams.set('rooms', props.roomCount);
  formattedUrl.searchParams.set(
    'staydates',
    [
      props.startDate.year,
      props.startDate.month,
      props.startDate.monthday,
      props.endDate.year,
      props.endDate.month,
      props.endDate.monthday
    ]
      .map((n) => n.toString().padStart(2, '0'))
      .join('_')
  );

  return formattedUrl.href;
}

function reserveOnlineFormatter(url, props) {
  const formattedUrl = new URL(url);
  const checkinDate = moment({
    year: props.startDate.year,
    month: props.startDate.month - 1,
    day: props.startDate.monthday
  }).format('YYYY-MM-DD');
  formattedUrl.searchParams.set('checkin', checkinDate);
  formattedUrl.searchParams.set('rooms', props.roomCount);
  formattedUrl.searchParams.set('adults', props.adultCount);
  const startDate = moment({
    year: props.startDate.year,
    month: props.startDate.month - 1,
    day: props.startDate.monthday
  });
  const endDate = moment({
    year: props.endDate.year,
    month: props.endDate.month - 1,
    day: props.endDate.monthday
  });
  const nights = endDate.diff(startDate, 'days');
  formattedUrl.searchParams.set('nights', nights);

  return formattedUrl.href;
}

export function format(url, props) {
  const { hostname } = new URL(url);

  if (hostname === 'booking.com' || hostname === 'www.booking.com') {
    return bookingFormatter(url, props);
  } else if (hostname.includes('tripadvisor.')) {
    return tripadvisorFormatter(url, props);
  } else if (hostname.includes('.reserve-online.net')) {
    return reserveOnlineFormatter(url, props);
  }

  return url.href;
}

export function supportsChildrenCount(url) {
  const { hostname } = new URL(url);

  if (hostname === 'booking.com' || hostname === 'www.booking.com') {
    return true;
  }

  return false;
}
