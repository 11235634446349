import * as locale from '../locale';

export const availableLocales = {
  'bg': locale.bg,
  'cs': locale.cs,
  'da': locale.da,
  'de': locale.de,
  'el': locale.el,
  'en': locale.en,
  'es': locale.es,
  'es-ar': locale.esAr,
  'et': locale.et,
  'fi': locale.fi,
  'fr': locale.fr,
  'hr': locale.hr,
  'hu': locale.hu,
  'id': locale.id,
  'is': locale.is,
  'it': locale.it,
  'ja': locale.ja,
  'lt': locale.lt,
  'lv': locale.lv,
  'ms': locale.ms,
  'nl': locale.nl,
  'no': locale.no,
  'pl': locale.pl,
  'pt-br': locale.ptBr,
  'pt-pt': locale.ptPt,
  'ro': locale.ro,
  'ru': locale.ru,
  'sk': locale.sk,
  'sl': locale.sl,
  'sr': locale.sr,
  'sv': locale.sv,
  'th': locale.th,
  'tl': locale.tl,
  'tr': locale.tr,
  'uk': locale.uk,
  'vi': locale.vi,
  'zh-cn': locale.zhCn
};

export const pluralize = (count, fallback, ...args) => {
  for (let i = 0; i < args.length; i++) {
    for (let j = 1; j < args[i].length; j++) {
      const value = args[i][0];
      const range = args[i][j];

      if (Array.isArray(range) && count >= range[0] && count <= range[1]) {
        return value;
      }

      if (count === range) {
        return value;
      }
    }
  }

  return fallback;
};

const fallbackLocaleKey = 'en';

export const detectLanguage = (overrideLang) => {
  const locales = Object.keys(availableLocales);
  const browserLanguages = [
    overrideLang,
    window.navigator.userLanguage,
    ...(window.navigator.languages || [])
  ].filter(lang => !!lang);

  for (let i = 0; i < browserLanguages.length; i++) {
    const lang = browserLanguages[i].toLowerCase();
    const result = locales.find(locale => locale.toLowerCase() === lang);

    if (result) {
      return result;
    }

    const suffixResult = locales.find(
      locale => locale.toLowerCase() === lang.split('-')[1]
    );

    if (suffixResult) {
      return suffixResult;
    }

    const prefixResult = locales.find(
      locale => locale.toLowerCase().split('-')[0] === lang.split('-')[0]
    );

    if (prefixResult) {
      return prefixResult;
    }
  }

  return fallbackLocaleKey;
};

const mergeTranslations = () => {
  const allKeys = Object.keys(availableLocales);
  const fallbackLocale = availableLocales[fallbackLocaleKey];
  const fallbackLocaleKeys = Object.keys(fallbackLocale);
  const translation = {};

  for (let i = 0; i < allKeys.length; i++) {
    const localeKey = allKeys[i];
    const locale = availableLocales[localeKey];

    for (let j = 0; j < fallbackLocaleKeys.length; j++) {
      const key = fallbackLocaleKeys[j];

      translation[key] = {
        ...translation[key],
        [localeKey]: locale[key] || fallbackLocale[key]
      };
    }
  }

  return translation;
};

export const mapLocaleToMoment = (locale) => {
  const map = {
    no: 'nb',
    tl: 'tl-ph'
  };

  return map[locale] || locale;
};

const translation = mergeTranslations();

export default translation;
