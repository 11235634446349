import 'moment/locale/de';

import { pluralize } from '../utils/translation';

export default {
  checkIn: 'Check-in-Datum',
  checkOut: 'Check-out-Datum',
  guests: 'Gäste',
  rooms: 'Zimmer',
  adults: 'Erwachsene',
  children: 'Kinder',
  checkAvailability: 'Verfügbarkeit prüfen',
  roomCount: '{count} Zimmer',
  adultCount: ({ count }) => pluralize(
    count,
    '{count} Erwachsene',
    ['{count} Erwachsener', 1]
  ),
  childrenCount: ({ count }) => pluralize(
    count,
    '{count} Kinder',
    ['{count} Kind', 1]
  )
};
