import 'moment/locale/ja';

export default {
  checkIn: 'チェックイン日',
  checkOut: 'チェックアウト日',
  guests: '宿泊人数',
  rooms: '客室',
  adults: '大人',
  children: '子供',
  checkAvailability: '空室検索',
  roomCount: '{count}室',
  adultCount: '大人{count}名',
  childrenCount: '子供{count}名'
};
