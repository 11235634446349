import 'moment/locale/bg';

import { pluralize } from '../utils/translation';

export default {
  checkIn: 'Дата на настаняване',
  checkOut: 'Дата на напускане',
  guests: 'Госці',
  rooms: 'Стаи',
  adults: 'Възрастни',
  children: 'Деца',
  checkAvailability: 'Проверка на наличността',
  roomCount: ({ count }) => pluralize(
    count,
    '{count} стаи',
    ['{count} стая', 1]
  ),
  adultCount: ({ count }) => pluralize(
    count,
    '{count} възрастни',
    ['{count} възрастен', 1]
  ),
  childrenCount: ({ count }) => pluralize(
    count,
    '{count} деца',
    ['{count} дете', 1]
  )
};
