import 'moment/locale/ro';

import { pluralize } from '../utils/translation';

export default {
  checkIn: 'Data Check-in',
  checkOut: 'Data Check-out',
  guests: 'Vizitatori',
  rooms: 'Camere',
  adults: 'Adulţi',
  children: 'Copii',
  checkAvailability: 'Verifică disponibilitatea',
  roomCount: ({ count }) => pluralize(
    count,
    '{count} camere',
    ['{count} cameră', 1]
  ),
  adultCount: ({ count }) => pluralize(
    count,
    '{count} adulți',
    ['{count} adult', 1]
  ),
  childrenCount: ({ count }) => pluralize(
    count,
    '{count} copii',
    ['{count} copil', 1]
  )
};
