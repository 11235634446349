import 'moment/locale/nl';

import { pluralize } from '../utils/translation';

export default {
  checkIn: 'Incheckdatum',
  checkOut: 'Uitcheckdatum',
  guests: 'Gasten',
  rooms: 'Kamers',
  adults: 'Volwassenen',
  children: 'Kinderen',
  checkAvailability: 'Bekijk beschikbaarheid',
  roomCount: ({ count }) => pluralize(
    count,
    '{count} kamers',
    ['{count} kamer', 1]
  ),
  adultCount: ({ count }) => pluralize(
    count,
    '{count} volwassenen',
    ['{count} volwassene', 1]
  ),
  childrenCount: ({ count }) => pluralize(
    count,
    '{count} kinderen',
    ['{count} kind', 1]
  )
};
