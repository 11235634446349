import 'moment/locale/id';

export default {
  checkIn: 'Tanggal check-in',
  checkOut: 'Tanggal check-out',
  guests: 'Tamu',
  rooms: 'Kamar',
  adults: 'Dewasa',
  children: 'Anak-anak',
  checkAvailability: 'Cek ketersediaan',
  roomCount: '{count} kamar',
  adultCount: '{count} dewasa',
  childrenCount: '{count} anak'
};
