import 'moment/locale/uk';

import { pluralize } from '../utils/translation';

export default {
  checkIn: 'Дата заїзду',
  checkOut: 'Дата виїзду',
  guests: 'Гостей',
  rooms: 'Номери',
  adults: 'Дорослі',
  children: 'Діти',
  checkAvailability: 'Шукати',
  roomCount: ({ count }) => pluralize(
    count,
    '{count} номерів',
    ['{count} номер', 1, 21],
    ['{count} номери', [2, 4], [22, 24]]
  ),
  adultCount: ({ count }) => pluralize(
    count,
    '{count} дорослих',
    ['{count} дорослий', 1, 21]
  ),
  childrenCount: ({ count }) => pluralize(
    count,
    '{count} дітей',
    ['{count} дитина', 1]
  )
};
