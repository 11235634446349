import '@babel/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import { collectPropsFromUrl } from './utils/props';

ReactDOM.render(
  <App {...collectPropsFromUrl(window.location.search)} />,
  document.getElementById('root')
);
