import 'moment/locale/tl-ph';

export default {
  checkIn: 'Petsa ng check-in',
  checkOut: 'Petsa ng check-out',
  guests: 'Mga bisita',
  rooms: 'Mga kuwarto',
  adults: 'Matanda',
  children: 'Bata',
  checkAvailability: 'Tingnan ang availability',
  roomCount: '{count} kuwarto',
  adultCount: '{count} matanda',
  childrenCount: '{count} bata'
};
