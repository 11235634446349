import 'moment/locale/sk';

import { pluralize } from '../utils/translation';

export default {
  checkIn: 'Od',
  checkOut: 'Do',
  guests: 'Hostia',
  rooms: 'Izby',
  adults: 'Dospelí',
  children: 'Deti',
  checkAvailability: 'Skontrolovať obsadenosť',
  roomCount: ({ count }) => pluralize(
    count,
    '{count} izieb',
    ['{count} izba', 1],
    ['{count} izby', [2, 4]]
  ),
  adultCount: ({ count }) => pluralize(
    count,
    '{count} dospelých',
    ['{count} dospelý', 1],
    ['{count} dospelí', [2, 4]]
  ),
  childrenCount: ({ count }) => pluralize(
    count,
    '{count} detí',
    ['{count} dieťa', 1],
    ['{count} deti', [2, 4]]
  )
};
