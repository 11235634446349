import 'moment/locale/cs';

import { pluralize } from '../utils/translation';

export default {
  checkIn: 'Datum příjezdu',
  checkOut: 'Datum odjezdu',
  guests: 'Hosté',
  rooms: 'Pokoje',
  adults: 'Dospělí',
  children: 'Děti',
  checkAvailability: 'Zkontrolovat dostupnost',
  roomCount: ({ count }) => pluralize(
    count,
    '{count} pokojů',
    ['{count} pokoj', 1],
    ['{count} pokoje', [2, 4]]
  ),
  adultCount: ({ count }) => pluralize(
    count,
    '{count} dospělých',
    ['{count} dospělý', 1],
    ['{count} dospělí', [2, 4]]
  ),
  childrenCount: ({ count }) => pluralize(
    count,
    '{count} děti',
    ['{count} dítě', 1]
  )
};
