import 'moment/locale/fi';

import { pluralize } from '../utils/translation';

export default {
  checkIn: 'Tulopäivä',
  checkOut: 'Lähtöpäivä',
  guests: 'Vierasta',
  rooms: 'Huoneita',
  adults: 'Aikuisia',
  children: 'Lapsia',
  checkAvailability: 'Tarkista saatavuus',
  roomCount: ({ count }) => pluralize(
    count,
    '{count} huonetta',
    ['{count} huone', 1]
  ),
  adultCount: ({ count }) => pluralize(
    count,
    '{count} aikuista',
    ['{count} aikuinen', 1]
  ),
  childrenCount: ({ count }) => pluralize(
    count,
    '{count} lasta',
    ['{count} lapsi', 1]
  )
};
