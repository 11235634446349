import 'moment/locale/et';

import { pluralize } from '../utils/translation';

export default {
  checkIn: 'Saabumine',
  checkOut: 'Lahkumine',
  guests: 'Külalised',
  rooms: 'Toad',
  adults: 'Täiskasvanud',
  children: 'Lapsed',
  checkAvailability: 'Kontrolli saadavust',
  roomCount: '{count} tuba',
  adultCount: ({ count }) => pluralize(
    count,
    '{count} täiskasvanut',
    ['{count} täiskasvanu', 1]
  ),
  childrenCount: ({ count }) => pluralize(
    count,
    '{count} last',
    ['{count} laps', 1]
  )
};
