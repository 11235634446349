import 'moment/locale/sr';

import { pluralize } from '../utils/translation';

export default {
  checkIn: 'Datum prijavljivanja',
  checkOut: 'Datum odjavljivanja',
  guests: 'Gosti',
  rooms: 'Jedinice',
  adults: 'Odrasli',
  children: 'Deca',
  checkAvailability: 'Proveri raspoloživost',
  roomCount: ({ count }) => pluralize(
    count,
    '{count} jedinica',
    ['{count} jedinice', [2, 4], [22, 24]]
  ),
  adultCount: ({ count }) => pluralize(
    count,
    '{count} odraslih',
    ['{count} odrasli', 1, 21],
    ['{count} odrasla', [2, 4], [22, 24]]
  ),
  childrenCount: ({ count }) => pluralize(
    count,
    '{count} dece',
    ['{count} dete', 1],
    ['{count} deteta', [2, 4]]
  )
};
