import 'moment/locale/vi';

export default {
  checkIn: 'Ngày nhận phòng',
  checkOut: 'Ngày trả phòng',
  guests: 'Khách',
  rooms: 'Phòng',
  adults: 'Người lớn',
  children: 'Trẻ em',
  checkAvailability: 'Kiểm tra phòng trống',
  roomCount: '{count} phòng',
  adultCount: '{count} người lớn',
  childrenCount: '{count} trẻ em'
};
