import 'moment/locale/tr';

export default {
  checkIn: 'Check-in tarihi',
  checkOut: 'Check-out tarihi',
  guests: 'Konuklar',
  rooms: 'Oda',
  adults: 'Yetişkin',
  children: 'Çocuk',
  checkAvailability: 'Yer durumuna bak',
  roomCount: '{count} oda',
  adultCount: '{count} yetişkin',
  childrenCount: '{count} çocuk'
};
