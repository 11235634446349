import 'moment/locale/lt';

import { pluralize } from '../utils/translation';

export default {
  checkIn: 'Įsiregistravimo data',
  checkOut: 'Išsiregistravimo data',
  guests: 'Svečiai',
  rooms: 'Numeriai',
  adults: 'Suaugusieji',
  children: 'Vaikai',
  checkAvailability: 'Tikrinti užimtumą',
  roomCount: ({ count }) => pluralize(
    count,
    '{count} numeriai',
    ['{count} numeris', 1, 21],
    ['{count} numerių', [10, 20], 30]
  ),
  adultCount: ({ count }) => pluralize(
    count,
    '{count} suaugusieji',
    ['{count} suaugusysis', 1, 21],
    ['{count} suaugusiųjų', [10, 20], 30]
  ),
  childrenCount: ({ count }) => pluralize(
    count,
    '{count} vaikai',
    ['{count} vaikas', 1],
    ['{count} vaikų', 10]
  )
};
