import 'moment/locale/lv';

import { pluralize } from '../utils/translation';

export default {
  checkIn: 'Reģistrēšanās datums',
  checkOut: 'Izrakstīšanās datums',
  guests: 'Viesi',
  rooms: 'Numuri',
  adults: 'Pieaugušie',
  children: 'Bērni',
  checkAvailability: 'Pārbaudīt pieejamību',
  roomCount: ({ count }) => pluralize(
    count,
    '{count} numuri',
    ['{count} numurs', 1, 21]
  ),
  adultCount: ({ count }) => pluralize(
    count,
    '{count} pieaugušie',
    ['{count} pieaugušais', 1, 21]
  ),
  childrenCount: ({ count }) => pluralize(
    count,
    '{count} bērni',
    ['{count} bērns', 1]
  )
};
