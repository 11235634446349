import 'moment/locale/pt';

import { pluralize } from '../utils/translation';

export default {
  checkIn: 'Data de check-in',
  checkOut: 'Data de check-out',
  guests: 'Clientes',
  rooms: 'Quartos',
  adults: 'Adultos',
  children: 'Crianças',
  checkAvailability: 'Verificar disponibilidade',
  roomCount: ({ count }) => pluralize(
    count,
    '{count} quartos',
    ['{count} quarto', 1]
  ),
  adultCount: ({ count }) => pluralize(
    count,
    '{count} adultos',
    ['{count} adulto', 1]
  ),
  childrenCount: ({ count }) => pluralize(
    count,
    '{count} crianças',
    ['{count} criança', 1]
  )
};
