import moment from 'moment';

class DateRange {
  constructor(start = moment(), end = this.nextStart) {
    this._start = start;
    this._end = end;
  }

  set start(value) {
    this._start = value;

    if (value >= this._end) {
      this._end = this.nextStart;
    }
  }

  set end(value) {
    this._end = value;

    if (value <= this._start) {
      this._start = this.prevEnd;
    }
  }

  get start() {
    return this._start;
  }

  get end() {
    return this._end;
  }

  get prevEnd() {
    return DateRange.prevDay(this._end);
  }

  get nextEnd() {
    return DateRange.nextDay(this._end);
  }

  get prevStart() {
    return DateRange.prevDay(this._start);
  }

  get nextStart() {
    return DateRange.nextDay(this._start);
  }

  get minStart() {
    return moment();
  }

  get maxStart() {
    return moment().add(1, 'years');
  }

  get minEnd() {
    return DateRange.nextDay(this.minStart);
  }

  get maxEnd() {
    return DateRange.nextDay(this.maxStart);
  }

  static prevDay(date) {
    return moment(date).subtract(1, 'days');
  }

  static nextDay(date) {
    return moment(date).add(1, 'days');
  }

  isSameStart(date) {
    return moment(this._start).isSame(date, 'day');
  }

  isSameEnd(date) {
    return moment(this._end).isSame(date, 'day');
  }

  isValidStart(date) {
    return moment(date).isBetween(this.minStart, this.maxStart, 'day', '[]');
  }

  isValidEnd(date) {
    return moment(date).isBetween(this.minEnd, this.maxEnd, 'day', '[]');
  }

  static getObject(date) {
    return ({
      year: moment(date).year(),
      month: moment(date).month() + 1,
      monthday: moment(date).date()
    });
  }
}

export default DateRange;
